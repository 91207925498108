<template>
  <div class="my-4">
    <Spinner v-if="isLoading"></Spinner>
    <div v-else>
      <b-row class="mx-0">
        <b-col md="8" offset-md="2">
          <b-card>
            <b-card-header class="image-profile">
              <span @click="openImageModal">
                <b-avatar
                  :src="imgData ? 'data:image/png;base64,' + imgData : ''"
                  size="10rem"
                  badge-variant="light"
                  badge-offset="-5px"
                >
                  <template #badge v-if="isMyProfile">
                    <input
                      @change="uploadImage($event)"
                      type="file"
                      ref="file"
                      style="display: none"
                      accept="image/png, image/gif, image/jpeg" />
                    <b-icon-camera-fill
                      @click="editImage"
                      class="edit-cursor"
                    ></b-icon-camera-fill
                  ></template> </b-avatar
              ></span>
              <div class="mt-2" style="color: white">
                <span class="author-name"
                  >{{ authorProfile.name }},
                  {{ authorProfile.dob | ageFilter }}</span
                >
                <blockquote v-if="authorProfile.tagline">
                  <i>{{ authorProfile.tagline }}</i>
                </blockquote>
              </div>
            </b-card-header>

            <b-card-body>
              <b-row class="my-2">
                <b-col lg="6" offset-lg="6" cols="12">
                  <b-button
                    variant="primary"
                    disabled
                    size="sm"
                    :class="{ 'ml-4': isMyProfile }"
                  >
                    <b-icon-people-fill> </b-icon-people-fill>&nbsp;
                    <b-badge variant="light">{{
                      authorProfile.followedBy.length
                    }}</b-badge>
                  </b-button>
                  <b-button
                    :disabled="isMyProfile"
                    size="sm"
                    :variant="isGlorified()"
                    class="ml-2"
                    @click="glorify()"
                  >
                    <b-spinner v-if="isGlorifying" small></b-spinner>
                    <b-icon-trophy v-else aria-label="trophy"></b-icon-trophy
                    >&nbsp;
                    <span class="mx-1" v-if="!isXSScreen">Glorified By</span>
                    <b-badge variant="light">{{
                      authorProfile.glorified.length
                    }}</b-badge>
                  </b-button>
                  <!-- <b-button
                    variant="primary"
                    disabled
                    size="sm"
                    :class="{ 'ml-2': isMyProfile }"
                  >
                    Glorified By
                    <b-badge variant="light">{{
                      authorProfile.glorified.length
                    }}</b-badge>
                  </b-button> -->
                  <b-button
                    v-if="!isMyProfile"
                    size="sm"
                    class="ml-2 my-2"
                    @click="followWriter()"
                    :title="isFollowing ? 'Unfollow' : 'Follow'"
                    :variant="isFollowing ? 'secondary' : 'outline-primary'"
                  >
                    <b-spinner v-if="isFollowProgress" small></b-spinner>
                    <template v-else>
                      <b-icon-person-x
                        v-if="isFollowing"
                        aria-hidden="true"
                      ></b-icon-person-x>
                      <b-icon-person-plus
                        v-else
                        aria-hidden="true"
                      ></b-icon-person-plus
                    ></template>
                    <span class="ml-1" v-if="!isXSScreen">{{
                      isFollowing ? "Unfollow" : "Follow"
                    }}</span>
                  </b-button>
                </b-col>
              </b-row>

              <b-row class="my-4">
                <b-col md="2" offset-md="1">
                  <label for="input-live"><strong>About:</strong></label>
                </b-col>
                <b-col :md="isMyProfile ? 7 : 8" sm="11" class="about-section">
                  <b-form-textarea
                    v-if="editAbout"
                    v-model="authorProfile.about"
                    rows="3"
                    max-rows="6"
                    required
                  ></b-form-textarea>
                  <p v-else>{{ authorProfile.about }}</p>
                </b-col>
                <b-col v-if="isMyProfile" sm="1" class="edit-cursor">
                  <b-icon-check-square-fill
                    @click="saveEditedData()"
                    v-if="editAbout"
                    aria-hidden="true"
                  ></b-icon-check-square-fill>
                  <b-icon-pencil-square
                    v-else
                    @click="editAbout = true"
                    aria-hidden="true"
                  ></b-icon-pencil-square>
                  <b-icon-x-circle
                    class="ml-2"
                    v-if="editAbout"
                    @click="editAbout = false"
                    aria-hidden="true"
                  ></b-icon-x-circle>
                </b-col>
              </b-row>

              <hr />

              <b-row class="my-4">
                <b-col md="2" offset-md="1">
                  <label for="input-live"><strong>Gender:</strong></label>
                </b-col>
                <b-col md="8" sm="11" class="about-section">
                  <p>{{ authorProfile.gender }}</p>
                </b-col>
              </b-row>

              <hr />

              <b-row class="my-4">
                <b-col md="2" offset-md="1">
                  <label for="input-live"><strong>Hobby:</strong></label>
                </b-col>
                <b-col :md="isMyProfile ? 7 : 8" sm="11" class="about-section">
                  <b-form-textarea
                    v-if="editHobby"
                    v-model="authorProfile.hobby"
                    rows="3"
                    max-rows="6"
                    required
                  ></b-form-textarea>
                  <p v-else>{{ authorProfile.hobby }}</p>
                </b-col>
                <b-col v-if="isMyProfile" sm="1" class="edit-cursor">
                  <b-icon-check-square-fill
                    @click="saveEditedData()"
                    v-if="editHobby"
                    aria-hidden="true"
                  ></b-icon-check-square-fill>
                  <b-icon-pencil-square
                    v-else
                    @click="editHobby = true"
                    aria-hidden="true"
                  ></b-icon-pencil-square>
                  <b-icon-x-circle
                    class="ml-2"
                    v-if="editHobby"
                    @click="editHobby = false"
                    aria-hidden="true"
                  ></b-icon-x-circle>
                </b-col>
              </b-row>

              <hr />

              <b-row class="my-4">
                <b-col md="2" offset-md="1">
                  <label for="input-live"
                    ><strong>Novels & Movies:</strong></label
                  >
                </b-col>
                <b-col :md="isMyProfile ? 7 : 8" sm="11" class="about-section">
                  <b-form-textarea
                    v-if="editFavMovies"
                    v-model="authorProfile.favMovies"
                    rows="3"
                    max-rows="6"
                    required
                  ></b-form-textarea>
                  <p v-else>{{ authorProfile.favMovies }}</p>
                </b-col>
                <b-col v-if="isMyProfile" sm="1" class="edit-cursor">
                  <b-icon-check-square-fill
                    @click="saveEditedData()"
                    v-if="editFavMovies"
                    aria-hidden="true"
                  ></b-icon-check-square-fill>
                  <b-icon-pencil-square
                    v-else
                    @click="editFavMovies = true"
                    aria-hidden="true"
                  ></b-icon-pencil-square>
                  <b-icon-x-circle
                    class="ml-2"
                    v-if="editFavMovies"
                    @click="editFavMovies = false"
                    aria-hidden="true"
                  ></b-icon-x-circle>
                </b-col>
              </b-row>

              <hr />

              <b-row class="my-4">
                <b-col md="2" offset-md="1">
                  <label for="input-live"
                    ><strong>Authors I like:</strong></label
                  >
                </b-col>
                <b-col :md="isMyProfile ? 7 : 8" sm="11" class="about-section">
                  <b-form-textarea
                    v-if="editFavAuthors"
                    v-model="authorProfile.likedAuthors"
                    rows="3"
                    max-rows="6"
                    required
                  ></b-form-textarea>
                  <p v-else>{{ authorProfile.likedAuthors }}</p>
                </b-col>
                <b-col v-if="isMyProfile" sm="1" class="edit-cursor">
                  <b-icon-check-square-fill
                    @click="saveEditedData()"
                    v-if="editFavAuthors"
                    aria-hidden="true"
                  ></b-icon-check-square-fill>
                  <b-icon-pencil-square
                    v-else
                    @click="editFavAuthors = true"
                    aria-hidden="true"
                  ></b-icon-pencil-square>
                  <b-icon-x-circle
                    class="ml-2"
                    v-if="editFavAuthors"
                    @click="editFavAuthors = false"
                    aria-hidden="true"
                  ></b-icon-x-circle>
                </b-col>
              </b-row>

              <hr />

              <b-row class="my-4">
                <b-col md="2" offset-md="1">
                  <label for="input-live"
                    ><strong>Language Known:</strong></label
                  >
                </b-col>
                <b-col :md="isMyProfile ? 7 : 8" sm="11" class="about-section">
                  <b-form-textarea
                    v-if="editLanguages"
                    v-model="authorProfile.languages"
                    rows="3"
                    max-rows="6"
                    required
                  ></b-form-textarea>
                  <p v-else>{{ authorProfile.languages }}</p>
                </b-col>
                <b-col v-if="isMyProfile" sm="1" class="edit-cursor">
                  <b-icon-check-square-fill
                    @click="saveEditedData()"
                    v-if="editLanguages"
                    aria-hidden="true"
                  ></b-icon-check-square-fill>
                  <b-icon-pencil-square
                    v-else
                    @click="editLanguages = true"
                    aria-hidden="true"
                  ></b-icon-pencil-square>
                  <b-icon-x-circle
                    class="ml-2"
                    v-if="editLanguages"
                    @click="editLanguages = false"
                    aria-hidden="true"
                  ></b-icon-x-circle>
                </b-col>
              </b-row>

              <hr />

              <b-row class="my-4" v-if="isMyProfile">
                <b-col md="2" offset-md="1">
                  <label for="input-live"><strong>Tag Line:</strong></label>
                </b-col>
                <b-col :md="isMyProfile ? 7 : 8" sm="11" class="about-section">
                  <b-form-textarea
                    v-if="editTagline"
                    v-model="authorProfile.tagline"
                    rows="3"
                    max-rows="6"
                    required
                  ></b-form-textarea>
                  <p v-else>{{ authorProfile.tagline }}</p>
                </b-col>
                <b-col sm="1" class="edit-cursor">
                  <b-icon-check-square-fill
                    @click="saveEditedData()"
                    v-if="editTagline"
                    aria-hidden="true"
                  ></b-icon-check-square-fill>
                  <b-icon-pencil-square
                    v-else
                    @click="editTagline = true"
                    aria-hidden="true"
                  ></b-icon-pencil-square>
                  <b-icon-x-circle
                    class="ml-2"
                    v-if="editTagline"
                    @click="editTagline = false"
                    aria-hidden="true"
                  ></b-icon-x-circle>
                </b-col>
              </b-row>

              <hr v-if="isMyProfile" />

              <b-row class="my-4">
                <b-col md="2" offset-md="1">
                  <label for="input-live" class="mt-2"
                    ><strong>Writings:</strong></label
                  >
                </b-col>
                <b-col md="10" offset-md="1" class="about-section p-2 p-md-4">
                  <b-list-group
                    class="ml-md-3"
                    v-for="article in articleCollection"
                    :key="article._id"
                  >
                    <b-list-group-item variant="secondary">
                      <b-row>
                        <b-col md="9">
                          <router-link
                            :to="{
                              name: 'FullPost',
                              params: { id: article._id },
                            }"
                            ><p class="article-link">
                              {{ article.title }}
                            </p></router-link
                          >
                        </b-col>
                        <b-col md="3">
                          <template v-if="article.likes.length > 0">
                            <b-icon-heart-fill
                              font-scale="1"
                              variant="success"
                            ></b-icon-heart-fill>
                            <span class="ml-2 mr-4">{{
                              article.likes.length
                            }}</span>
                          </template>

                          <template v-if="article.comments.length > 0">
                            <b-icon-chat-left-dots-fill
                              font-scale="1"
                              variant="info"
                            ></b-icon-chat-left-dots-fill>

                            <span class="mx-2">
                              {{ article.comments.length }}
                            </span>
                          </template>
                        </b-col>
                      </b-row>
                    </b-list-group-item>
                  </b-list-group>
                </b-col>
              </b-row>

              <template v-if="isMyProfile">
                <hr />
                <b-row class="my-4">
                  <b-col md="2" offset-md="1"
                    ><b-icon-gear
                      font-scale="1"
                      variant="info"
                      class="mr-2"
                    ></b-icon-gear>
                    <label for="input-live" text-variant="info"
                      ><a style="color: #17a2b8" href="/settings"
                        ><strong>Settings</strong></a
                      ></label
                    >
                  </b-col>
                </b-row>
              </template>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </div>

    <ImageModal
      v-if="showImageModal"
      :imgSrc="authorProfile.image.data"
      @closedModal="showImageModal = false"
    ></ImageModal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCardBody,
  BCardHeader,
  BFormTextarea,
  BListGroup,
  BListGroupItem,
  BIconTrophy,
  BIconPeopleFill,
  BIconGear,
  BIconChatLeftDotsFill,
  BIconHeartFill,
  BIconXCircle,
  BIconPencilSquare,
  BIconCheckSquareFill,
  BIconPersonX,
  BIconPersonPlus,
  BIconCameraFill,
  BSpinner,
} from "bootstrap-vue";

export default {
  components: {
    Spinner: () =>
      import(/* webpackPrefetch: true */ "../components/Spinner.vue"),
    ImageModal: () =>
      import(/* webpackPrefetch: true */ "./modals/ImageModal.vue"),
    BAvatar,
    BBadge,
    BButton,
    BCard,
    BCardBody,
    BCardHeader,
    BFormTextarea,
    BListGroup,
    BListGroupItem,
    BIconCameraFill,
    BIconTrophy,
    BIconPeopleFill,
    BIconGear,
    BIconChatLeftDotsFill,
    BIconHeartFill,
    BIconXCircle,
    BIconPencilSquare,
    BIconCheckSquareFill,
    BIconPersonX,
    BIconPersonPlus,
    BSpinner,
  },

  data() {
    return {
      authorProfile: {},
      isLoading: true,
      authorId: "",
      editAbout: false,
      editHobby: false,
      editFavMovies: false,
      editFavAuthors: false,
      editLanguages: false,
      editTagline: false,
      articleCollection: [],
      imgData: "",
      isMobile: false,
      isXSScreen: false,
      showImageModal: false,
      isGlorifying: false,
      isFollowProgress: false,
    };
  },

  computed: {
    ...mapGetters(["userDetails"]),

    isFollowing() {
      return this.authorProfile?.followedBy?.some(
        (element) => element.id == this.userDetails.id
      );
    },

    isMyProfile() {
      return this.userDetails.id === this.authorId;
    },
  },

  async mounted() {
    this.isMobile = window.screen.width < 1024;
    this.isXSScreen = window.screen.width < 768;
    if (this.$route.params.id === "myprofile") {
      this.authorId = this.userDetails.id;
    } else {
      this.authorId = this.$route.params.id;
    }

    try {
      this.isLoading = true;
      let response = await Promise.all([
        this.getAuthorProfile({ authorId: this.authorId }),
        this.fetchArticlesByAuthor({ authorId: this.authorId }),
      ]);

      this.authorProfile = response[0];
      this.articleCollection = response[1];

      this.imgData = this.authorProfile?.image?.data?.toString("base64");
    } catch (e) {
      if (e.response.status === 401) {
        this.signOut();
      }
      console.error(e);
    } finally {
      this.isLoading = false;
    }
  },

  methods: {
    ...mapActions([
      "editProfileImage",
      "fetchArticlesByAuthor",
      "followAuthor",
      "getAuthorProfile",
      "glorifyUser",
      "saveProfileData",
      "sendNotification",
      "signOut",
    ]),

    editImage() {
      this.$refs.file.click();
    },

    openImageModal() {
      if (this.isMyProfile) {
        return;
      }
      this.showImageModal = true;
    },

    async uploadImage(event) {
      const file = event.target.files[0];
      const filename = file.name;
      const maxWidth = 640;
      const maxHeight = 520;
      const MIME_TYPE = "image/jpeg";
      const QUALITY = 0.9;

      const blobURL = URL.createObjectURL(file);
      const img = new Image();
      img.src = blobURL;
      img.onerror = function () {
        URL.revokeObjectURL(this.src);
        // Handle the failure properly
        console.log("Cannot load image");
      };
      img.onload = async () => {
        URL.revokeObjectURL(this.src);
        let newWidth = img.width;
        let newHeight = img.height;
        if (newWidth > newHeight) {
          if (newWidth > maxWidth) {
            newHeight = Math.round((newHeight * maxWidth) / newWidth);
            newWidth = maxWidth;
          }
        } else {
          if (newHeight > maxHeight) {
            newWidth = Math.round((newWidth * maxHeight) / newHeight);
            newHeight = maxHeight;
          }
        }

        const canvas = document.createElement("canvas");
        canvas.width = newWidth;
        canvas.height = newHeight;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, newWidth, newHeight);
        var newImageData = canvas.toDataURL(MIME_TYPE, QUALITY);
        var result_image_obj = new Image();
        result_image_obj.src = newImageData;

        var arr = newImageData.split(","),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]),
          n = bstr.length,
          u8arr = new Uint8Array(n);

        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }

        let newImg = new File([u8arr], filename, { type: mime });

        try {
          await this.editProfileImage({
            userId: this.userDetails.id,
            image: newImg,
          });

          this.imgData = newImageData.substr(23);

          this.$bvToast.toast(`Profile image updated successfully`, {
            title: `Successful`,
            autoHideDelay: 2000,
            variant: `primary`,
            solid: true,
          });
        } catch (e) {
          if (e.response.status === 401) {
            this.signOut();
          } else {
            console.error(e);
            this.$bvToast.toast(`Some error Occured!`, {
              title: `Error`,
              autoHideDelay: 2000,
              variant: `danger`,
              solid: true,
            });
          }
        }
      };
    },

    async saveEditedData() {
      try {
        await this.saveProfileData({
          id: this.userDetails.id,
          profileData: {
            about: this.authorProfile.about,
            hobby: this.authorProfile.hobby,
            favMovies: this.authorProfile.favMovies,
            likedAuthors: this.authorProfile.likedAuthors,
            languages: this.authorProfile.languages,
            tagline: this.authorProfile.tagline,
          },
        });

        this.editAbout = false;
        this.editHobby = false;
        this.editFavMovies = false;
        this.editFavAuthors = false;
        this.editLanguages = false;
        this.editTagline = false;

        this.$bvToast.toast(`Data Saved Successfully!`, {
          title: `Success`,
          autoHideDelay: 2000,
          variant: `warning`,
          solid: true,
        });
      } catch (e) {
        if (e.response.status === 401) {
          this.signOut();
        } else {
          console.error(e);
          this.$bvToast.toast(`Some error Occured!`, {
            title: `Error`,
            autoHideDelay: 2000,
            variant: `danger`,
            solid: true,
          });
        }
      }
    },

    isGlorified() {
      return this.authorProfile.glorified?.indexOf(this.userDetails.id) > -1
        ? "warning"
        : "outline-warning";
    },

    async glorify() {
      if (this.isMyProfile || this.isGlorifying) {
        return;
      }

      try {
        this.isGlorifying = true;
        await this.glorifyUser({
          id: this.authorProfile._id,
          userId: this.userDetails.id,
        });

        let ind = this.authorProfile.glorified?.indexOf(this.userDetails.id);

        if (~ind) {
          this.authorProfile.glorified.splice(ind, 1);
        } else {
          this.authorProfile.glorified.push(this.userDetails.id);
        }

        this.$bvToast.toast(
          `You have ${~ind ? "un-glorified" : "glorified"} ${
            this.authorProfile.name
          }!`,
          {
            title: `${~ind ? "Un-Glorified" : "Glorified"}`,
            autoHideDelay: 2000,
            variant: `${~ind ? "danger" : "warning"}`,
            solid: true,
          }
        );

        if (!~ind) {
          await this.sendNotification({
            payload: {
              message: "has glorified you!",
              userId: this.userDetails.id,
              userName: this.userDetails.name,
              time: new Date(),
              id: this.authorProfile._id,
            },
          });
        }
      } catch (e) {
        console.error(e);
        if (e.response.status === 401) {
          this.signOut();
          return;
        }
        this.$bvToast.toast(`An error occured, please try again`, {
          title: `Error!`,
          autoHideDelay: this.dismissSecs,
          variant: `danger`,
          solid: true,
        });
      } finally {
        this.isGlorifying = false;
      }
    },

    async followWriter() {
      if (this.isMyProfile || this.isGlorifying) {
        return;
      }

      try {
        this.isFollowProgress = true;
        let res = await this.followAuthor({
          payload: {
            user: {
              id: this.userDetails.id,
              name: this.userDetails.name,
            },
            author: {
              id: this.authorProfile._id,
              name: this.authorProfile.name,
            },
          },
        });

        this.authorProfile.followedBy = res.followedBy;

        this.$bvToast.toast(
          `You have ${this.isFollowing ? "followed" : "unfollowed"} ${
            this.authorProfile.name
          }!`,
          {
            title: `${this.isFollowing ? "Followed" : "UnFollowed"}`,
            autoHideDelay: 2000,
            variant: `${this.isFollowing ? "warning" : "danger"}`,
            solid: true,
          }
        );

        if (this.isFollowing) {
          this.sendNotification({
            payload: {
              message: "has followed you!",
              userId: this.userDetails.id,
              userName: this.userDetails.name,
              time: new Date(),
              id: this.authorProfile._id,
            },
          });
        }
      } catch (e) {
        console.error(e);
        if (e.response.status === 401) {
          this.signOut();
          return;
        }
        this.$bvToast.toast(`An error occured, please try again`, {
          title: `Error!`,
          autoHideDelay: this.dismissSecs,
          variant: `danger`,
          solid: true,
        });
      } finally {
        this.isFollowProgress = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.author-name {
  font-weight: bold;
  font-style: italic;
  font-size: 20px;
}
.about-section {
  text-align: left;
}
.article-link {
  color: black;
  margin-bottom: 0;
}
blockquote::before {
  content: open-quote;
}
blockquote::after {
  content: close-quote;
}
blockquote {
  quotes: "“" "”" "‘" "’";
}
.image-profile {
  background-image: linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.9)),
    url("../assets/writing-image.jpg");
  height: 50%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.edit-cursor {
  cursor: pointer;
}

@media only screen and (max-width: 767px) {
  .about-section {
    text-align: center;
  }

  .image-profile {
    background-image: linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 1)),
      url("../assets/writing-image-mobile.jpg");
  }
}
</style>
